import "./src/tailwind.css";
//import "typeface-inter";

export const onInitialClientRender = () => {
  document.documentElement.classList.add("tagrush_mount");
};

export const onRouteUpdate = () => {
  setTimeout(() => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ event: `gatsby-route-change` });
  }, 50);
};
